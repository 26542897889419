import React, { useState } from "react";
import styled from "styled-components";
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import Logo from "../../assest/Logo.png";

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  height: 3rem;
  object-fit: cover;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: white;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const Link = styled(NavLink)`
  margin: 0 1rem;
  text-decoration: none;
  color: black;
  &.active {
    color: #ff6b6b;
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const AnchorLink = styled.a`
  margin: 0 1rem;
  text-decoration: none;
  color: black;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const SignUpButton = styled(NavLink)`
  border: 1px solid olivedrab;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
  &:hover {
    background-color: olivedrab;
    color: white;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Nav>
      <Image src={Logo} alt="logo"></Image>
      <HamburgerButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <IoClose /> : <IoMenu />}
      </HamburgerButton>

      <NavItems isOpen={isOpen}>
        <Link
          to="/home"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Home
        </Link>
        <Link to="/templates" activeClassName="active">
          Templates
        </Link>
        <Link to="/about" activeClassName="active">
          {" "}
          About Us
        </Link>
        <Link to="/pricing" activeClassName="active">
          Pricing
        </Link>
        <Link to="/contact">Contact</Link>
        <SignUpButton to="/auth" activeClassName="active">
          Sign Up
        </SignUpButton>
      </NavItems>
    </Nav>
  );
};

export default Navbar;
