
import styled from 'styled-components';

const TextAreaWrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledTextArea = styled.textarea`
  width: 92%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const QuestionText = styled.p`
  font-weight: bold;
`;
const TextArea = ({ question, answer, handleOptions }) => {
    return (
      <TextAreaWrapper>
        <QuestionText>  {question.questionNumber}.{question.questionStatement}</QuestionText>
        <StyledTextArea value={answer[question.questionNumber - 1] }   onChange={(e) => handleOptions(question.questionNumber - 1, e.target.value)} />
      </TextAreaWrapper>
    );
  };

  export default TextArea;