import React from 'react';
import styled from 'styled-components';
import { FaClipboardList, FaMobileAlt, FaDesktop, FaClock, FaChartLine, FaFileAlt, FaLock } from 'react-icons/fa';

const features = [
  {
    title: 'Ready-to-Use Templates',
    description: 'Choose from various pre-built survey templates for different HR needs or use your own template.',
    icon: <FaClipboardList/>
  },
  {
    title: 'Multi-channel',
    description: 'Participants can take survey from their mobile or desktop through URL or QR scan.',
    icon: <FaMobileAlt/>
  },
  {
    title: 'Real-Time Monitoring',
    description: 'Get instant insights and track responses as they come in.',
    icon: <FaClock/>
  },
  {
    title: 'Graphs and Analytics',
    description: 'Analyze data in real-time to generate online graphs and download them.',
    icon: <FaChartLine/>
  },
  {
    title: 'Automated Reports',
    description: 'Generate comprehensive reports that summarize key findings with just one click. Export raw data into CSV files.',
    icon: <FaFileAlt/>
  },
  {
    title: 'Data Security and Privacy',
    description: 'Data is kept securely in the cloud and compliant with privacy guidelines.',
    icon: <FaLock/>
  }
];
const Container = styled.div`
  max-width: 1200px;
  margin:auto;
  padding: 10px 20px 0px;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
  color:#111111 ;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #464646;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const FeatureItem = styled.div`
background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Icon = styled.div`
  font-size: 22px;
  margin-bottom: 5px;
  
`;

const FeatureTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const FeatureDescription = styled.p`
  font-size: 14px;
  color: #464646;
`;

const HeaderTitle = styled.div`
  color: #F5551B;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const FeatureSection = () => {
  return (
    <Container>
      <Header>
      <HeaderTitle>FEATURES</HeaderTitle>
        <Title>Features Designed to Simplify Your Survey Process</Title>
        <Subtitle>Everything you need to build, launch, and analyze surveys quickly and effectively.</Subtitle>
      </Header>
      <FeaturesGrid>
        {features.map((feature, index) => (
          <FeatureItem key={index}>
            <Icon ><span style={{color:"orangered"}}>{feature.icon}</span></Icon>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureItem>
        ))}
      </FeaturesGrid>
    </Container>
  );
};

export default FeatureSection;