import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaLinkedin } from "react-icons/fa6";
import { useLocation ,useNavigate } from "react-router-dom";


const LoginContainer = styled.div`
  max-width: 400px;
  margin: auto;
  padding: 20px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    max-width: 300px;
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  border-color: ${(props) => (props.error ? "red" : "")};
`;
const Select = styled.select`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;
const Option = styled.option`
  color: gray;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const LinkedInButton = styled.div`
  border: 1.5px solid #0077b5;
  background-color: blue;
  margin-bottom: 10px;
  color: white;
  border-radius: 8px;
  box-shadow: 1px 1px 1px white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  font-size: 16x;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  cursor: pointer;
  width: 50%;
  margin: auto;
  font-weight: 700;
  @media (max-width: 768px) {
    width: 90%;
  }
  .icon {
    margin-right: 5px;
    font-size: 18px;
    box-shadow: 1px 1px 1px #00072d;
    border-radius: 5px;
  }
`;

const Tag = styled.div`
  font-size: 14px;
  color: gray;
  margin: auto;
  margin-bottom: 0.3rem;
`;

const ToggleLink = styled.div`
  text-align: center;
  color: #0077b5;
  cursor: pointer;
  margin: auto;
  margin-top: 0.3rem;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 0.8rem;
  padding: 0.3rem;
  margin-top: -8px;`

const LoadingMessage = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const Login = () => {
  const [hasAccount, setHasAccount] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [surveyType, setSurveyType] = useState(null);
  const [TemplateId, setTemplateId] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const location = useLocation();
  const navigate =useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const surveyTypeParam = queryParams.get("surveyType");
    const templateIdParam = queryParams.get("TemplateId");

    if (surveyTypeParam) setSurveyType(surveyTypeParam);
    if (templateIdParam) setTemplateId(templateIdParam);
  }, [location]);

  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    companyType: "",
    email: "",
    password: "",
    username: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
    const error = validateField(name, value);
    setFormErrors({ ...formErrors, [name]: error });
  };

  const toggleMode = () => {
    setHasAccount(!hasAccount);
    setError(null);
    setFormErrors({});
    setFormData({
      fullName: "",
      companyName: "",
      companyType: "",
      email: "",
      password: "",
      username: "",
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const errors = {
      email: validateField("email", formData.email),
      password: validateField("password", formData.password),
    };

    if (Object.values(errors).some((error) => error !== "")) {
      setFormErrors(errors);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/user/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const res = await response.json();
      const data = res?.data;
      console.log("Login successful:", data);

      const queryParams = new URLSearchParams({
        admin_id: data._id,
        admin_email: data.adminEmail,
        access_id: data.authToken,
        super_admin: data.isSuper,
        orgName: data.orgName,
        orgId: data.orgId,
        isAdmin: data.isAdmin,
        isOwner: data.isOwner,
        surveyType: surveyType || "",
        TemplateId: TemplateId || null,
      }).toString();

      setFormData({
        fullName: "",
        companyName: "",
        companyType: "",
        email: "",
        password: "",
        username: "",
      });
      window.location.href = `${process.env.REACT_APP_REDIRECTED_URL}/${
        data?.isSuper || data?.isOwner
          ? "super-admin/survey/add"
          : "admin/create_survey"
      }?${queryParams}`;
      console.log(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const errors = {
      fullName: validateField("fullName", formData.fullName),
      companyName: validateField("companyName", formData.companyName),
      companyType: validateField("companyType", formData.companyType),
      email: validateField("email", formData.email),
      password: validateField("password", formData.password),
    };

    if (Object.values(errors).some((error) => error !== "")) {
      setFormErrors(errors);
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organization/signUp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.fullName,
            orgName: formData.companyName,
            orgType: formData.companyType,
            email: formData.email,
            password: formData.password,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "An error occurred");
      }

      const res = await response.json();
      const data = res?.data;
      setFormData({
        fullName: "",
        companyName: "",
        companyType: "",
        email: "",
        password: "",
        username: "",
      });
       navigate('/thankyou')
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const disableLogin = () => {
    const hasErrors = Object.values(formErrors).some((error) => error !== "");
    if (hasAccount) {
      return !(formData.email && formData.password) || hasErrors;
    } else {
      return (
        !(
          formData.fullName &&
          formData.companyName &&
          formData.companyType &&
          formData.email &&
          formData.password
        ) || hasErrors
      );
    }
  };

  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        return !value || !emailIsValid(value)
          ? "Please enter a valid email address."
          : "";
      case "password":
        return !value || value.length < 8
          ? "Password must be at least 8 characters long."
          : "";
      case "fullName":
        if (!value || value.trim().length === 0)
          return "Full name is required.";
        if (value.trim().length > 20)
          return "Full name should not exceed 20 characters.";
        return "";
      case "companyName":
        if (!value || value.trim().length === 0)
          return "Company name is required.";
        if (value.trim().length > 20)
          return "Company name should not exceed 20 characters.";
        return "";
      case "companyType":
        return !value ? "Please select a company type." : "";
      default:
        return "";
    }
  };
  return (
    <LoginContainer>
      <Title>It's time to listen to your team</Title>
      <Form onSubmit={hasAccount ? handleLogin : handleSignup}>
        <LinkedInButton type="button">
          <FaLinkedin className="icon" /> {hasAccount ? "Log in" : "Sign up"}{" "}
          with LinkedIn
        </LinkedInButton>
        <Tag>Or</Tag>
        {hasAccount ? (
          <>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              error={formErrors.email}
            />
            {formErrors.email && (
              <ErrorMessage>{formErrors.email}</ErrorMessage>
            )}
            <Input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              error={formErrors.password}
            />
            {formErrors.password && (
              <ErrorMessage>{formErrors.password}</ErrorMessage>
            )}
          </>
        ) : (
          <>
            <Input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={formData.fullName}
              onChange={handleInputChange}
              error={formErrors.fullName}
            />
            {formErrors.fullName && (
              <ErrorMessage>{formErrors.fullName}</ErrorMessage>
            )}
            <Input
              type="text"
              name="companyName"
              placeholder="Company Name"
              value={formData.companyName}
              onChange={handleInputChange}
              error={formErrors.companyName}
            />
            {formErrors.companyName && (
              <ErrorMessage>{formErrors.companyName}</ErrorMessage>
            )}
            <Select
              name="companyType"
              placeholder="Company Type"
              value={formData.companyType}
              onChange={handleInputChange}
              error={formErrors.companyType}
            >
              <Option value="" disabled hidden>
                Company Type
              </Option>
              <Option value="consultancy">Consultancy</Option>
              <Option value="corporate">Corporate</Option>
            </Select>
            {formErrors.companyType && (
              <ErrorMessage>{formErrors.companyType}</ErrorMessage>
            )}
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              error={formErrors.email}
            />
            {formErrors.email && (
              <ErrorMessage>{formErrors.email}</ErrorMessage>
            )}
            <Input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              error={formErrors.password}
            />
            {formErrors.password && (
              <ErrorMessage>{formErrors.password}</ErrorMessage>
            )}
          </>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {loading && <LoadingMessage>Loading...</LoadingMessage>}
        <Button type="submit" disabled={loading || disableLogin()}>
          {hasAccount ? "Log In" : "Get Started"}
        </Button>
      </Form>
      <ToggleLink onClick={toggleMode}>
        {hasAccount
          ? "Don't have an account? Sign up"
          : "Already have an account? Log in"}
      </ToggleLink>
    </LoginContainer>
  );
};

export default Login;
