import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

// Styled components for card and slider wrapper
const CardWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media(max-width:768px){
    height:13rem;
  }
`;

const Icon = styled.div`
  font-size: 30px;
  margin-bottom: 16px;
`;

const Title = styled.h3`
  font-size: 20px;
  color: #111111;
  margin-bottom: 12px;
  @media(max-width:768px){
    font-size: 18px;
  }

`;

const Description = styled.p`
  font-size: 15px;
  color: #464646;
  text-align:justify;
  text-justify: inter-word;
  line-height: 1.4;
  @media(max-width:768px){
    font-size: 14px;
  }
`;

const StepNumber = styled.div`
  margin-top: 16px;
  font-size: 14px;
  color: #F5551B;
  text-align: right;
`;

const SliderWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;

  .slick-slide > div {
    padding: 0 10px; /* Adjust as necessary */
  }

  @media (max-width: 768px) {
    padding: 10px; /* Adjust as necessary */
  }
`;

const StepCard = ({ icon, title, description, stepNumber }) => (
  <CardWrapper>
    <div>
      <Icon>{icon}</Icon>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </div>
    <StepNumber>Step {stepNumber}</StepNumber>
  </CardWrapper>
);

const StepSlider = ({ steps }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust based on your design
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        {steps.map((step, index) => (
          <StepCard
            key={index}
            icon={step.icon}
            title={step.title}
            description={step.description}
            stepNumber={index + 1}
          />
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default StepSlider;
