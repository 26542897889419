import React, { useState } from "react";
import styled from "styled-components";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const Container = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  @media(max-width:768px){
    display: flex;
  }
`;

const PlanCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 300px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: ${(props) =>
    props.isPro ? "orangered" : "transparent"};
`;

const PlanHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${(props) =>
    props.isPro ? "white" : "black"};
`;

const PlanPrice = styled.div`
  font-size: 20px;
  margin: 10px 0;
  font-weight: 1000;
`;

const Button = styled.button`
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px 0;

  &:hover {
    background-color: #c0392b;
  }
`;

const Tag = styled.div`
  margin: 0.5rem;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  color: ${(props) =>
    props.isPro ? "white" : "orange"};
`;

const PricingTable = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  max-width: 360px;
  margin-bottom: 1rem;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background-color: #f0f0f0;
`;

const TableBody = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
`;

const HeaderCell = styled.div`
  padding: 15px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.isPro ? "#ff5722" : "transparent")};
`;

const Cell = styled.div`
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
  color: ${(props) => (props.color ? "orangered" : "")};
  font-weight: 500;
  font-size: 0.9rem;
  /* text-transform: capitalize; */
`;

const MobilePricingPlans = ({ plansData }) => {
  const [showFeatures, setShowFeatures] = useState({});

  const toggleFeatures = (planName) => {
    setShowFeatures((prevState) => ({
      ...prevState,
      [planName]: !prevState[planName],
    }));
  };

  function makeSentence(str) {
    let words = str.split(/(?=[A-Z])/)
                   .map(word => word.toLowerCase());
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(' ') ;
  }
  return (
    <Container>
      {plansData.map((plan, index) => (
        <>
        <PlanCard key={index} isPro={plan.isPro}>
          <PlanHeader isPro={plan.isPro}>{plan.planName}</PlanHeader>
          <PlanPrice isPro={plan.isPro}>{plan.price}</PlanPrice>
          <Button>Get Started Today</Button>
          <Tag isPro={plan.isPro} onClick={() => toggleFeatures(plan.planName)}>
            Show features {showFeatures[plan.planName] ? <FaArrowUp /> : <FaArrowDown />}
          </Tag>
        </PlanCard>
        {showFeatures[plan.planName] && (
            <PricingTable>
              <TableHeader>
                <HeaderCell isPro={plan.isPro}>{plan.planName}</HeaderCell>
              </TableHeader>
              <TableBody>
                {Object.keys(plan.features).map((feature, fIndex) => (
                  <React.Fragment key={fIndex}>
                    <Cell>{makeSentence(feature)}</Cell>
                    <Cell>{plan.features[feature]==='Yes'?"✓":plan.features[feature]}</Cell>
                  </React.Fragment>
                ))}
              </TableBody>
            </PricingTable>
          )}
        </>

      ))}
    </Container>
  );
};

export default MobilePricingPlans;
