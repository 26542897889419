import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import TransformBanner from "./common/Banner";
import Footer from "./components/Footer/footer";
import Navbar from "./components/Header/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import PricingPlans from "./components/Subscriptions/Pricing";
import Templates from "./components/Templates/template";
import Login from "./components/Auth/Auth";
import NotFoundPage from "./components/404Error/404Error";
import ScrollToTop from './components/404Error/scrollTop';
import Contact from './components/Contact/Contact';
import WelcomeCard from './components/pages/ThankYou';

function App() {
  const location = useLocation();
  const validPaths = ["/", "/home", "/about", "/pricing", "/templates", "/auth"];

  return (
    <>
      <ScrollToTop/>
      <Navbar />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/pricing" element={<PricingPlans />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/auth" element={<Login />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/thankyou" element={<WelcomeCard />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {validPaths.includes(location.pathname) && location.pathname !== '/auth' && <TransformBanner />}
      <Footer />
    </>
  );
}

export default App;
