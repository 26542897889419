
const fetchExcelToJson = async (id, setData  ,setLoading ,setError ,name) => {
  try {
    setError(null)
    setLoading(true)
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/survey/templates/${name==="FPA"?'fpa':id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();

 (  name==="FPA"? setData(data):setData(data?.questions))
  } catch (error) {
    console.error('Error converting Excel to JSON:', error);
    setError('Error in getting Questions ', error)
    setData(null);
  }finally{
    setLoading(false)
  }
};

export default fetchExcelToJson;