import React, { useState } from "react";
import styled from "styled-components";
import McqQuestion from "./McqQuestion";
import TextArea from "./TextQuestion";
import { IoMdClose } from "react-icons/io";
import RatingComponent from "./Rating";

const FormContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  position: relative;
  padding-bottom: 60px;
  height: ${(props) => (props.check ? "65%" : "75%")};
  width: 400px;
  @media (max-width: 768px) {
     width: ${(props) => (props.check ? "70%" : "85%")};
     height: ${(props) => (props.check ? "60%" : "70%")};
   }
   @media (max-width: 480px) {
     width: ${(props) => (props.check ? "90%" : "85%")};
   }
`;

const QuestionContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100% - 30px);
  padding-bottom: 20px;
`;

const Button = styled.button`
  background-color: #007bff;
  display: flex;
  color: white;
  background-color: orangered;
  box-shadow: 1px 1px 1px white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
  background-color: white;
`;
const Message = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.error ? "red" : "gray")};
`;
const CloseIcon = styled(IoMdClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
`;

const ThankYouNote = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
`;

function PreviewTemplate({ handleClose, Questions, loading, error, survey }) {
  const [answer, setAnswer] = useState({});
  const [activepage, setActivePage] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dataMap, setDataMap] = useState(new Map());
  const [answers, setAnswers] = useState({});
  const TotalPages =
    survey === "FPA" ? Questions?.length : Math.ceil(Questions?.length / 5);
  const startIndex = (activepage - 1) * 5;
  const endIndex = startIndex + 5;

  const HandleAnswers = (questionNumber, fieldIndex, optionIndex) => {
    const newDataMap = new Map(dataMap);

    if (!newDataMap.has(questionNumber)) {
      newDataMap.set(questionNumber, []);
    }

    const questionData = newDataMap.get(questionNumber);
    const existingOptionIndex = questionData.findIndex(
      (item) =>
        item.optionIndex === optionIndex && item.fieldIndex !== fieldIndex
    );

    if (existingOptionIndex !== -1) {
      questionData.splice(existingOptionIndex, 1);
    }

    const existingFieldIndex = questionData.findIndex(
      (item) => item.fieldIndex === fieldIndex
    );

    if (existingFieldIndex !== -1) {
      questionData[existingFieldIndex].optionIndex = optionIndex;
    } else {
      questionData.push({ fieldIndex, optionIndex });
    }

    newDataMap.set(questionNumber, questionData);
    setDataMap(newDataMap);
    const sortedOptionIndexes = [...questionData]
      .sort((a, b) => Number(a.fieldIndex) - Number(b.fieldIndex))
      .map((item) => item.optionIndex);

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionNumber]: sortedOptionIndexes,
    }));
  };

  const handleOptions = (index, answer) => {
    setAnswer((pre) => ({ ...pre, [index]: answer }));
  };

  if (loading) {
    return (
      <FormContainer check={survey === "FPA"}>
        <Message>Loading.......</Message>
      </FormContainer>
    );
  }
  if (error) {
    return (
      <FormContainer check={survey === "FPA"}>
        <Message error>{error}</Message>
      </FormContainer>
    );
  }

  if (Questions?.length === 0) {
    return (
      <FormContainer check={survey === "FPA"}>
        <Message>No Questions Found</Message>
      </FormContainer>
    );
  }

  if (isSubmitted) {
    return (
      <FormContainer check={survey === "FPA"}>
        <CloseIcon onClick={() => handleClose()} />
        <ThankYouNote>Thank you for completing the survey!</ThankYouNote>
      </FormContainer>
    );
  }
  const handleSubmit = () => {
    setIsSubmitted(true);
  };
  return (
    <FormContainer
      check={survey === "FPA"}
      onClick={(e) => e.stopPropagation()}
    >
      <CloseIcon onClick={handleClose} />
      {survey === "FPA" ? (
        <QuestionContainer>
          {Questions?.slice(activepage - 1, activepage)?.map((item, index) => (
            <RatingComponent
              key={item.id}
              item={item}
              HandleAnswers={HandleAnswers}
              dataMap={dataMap}
            />
          ))}
        </QuestionContainer>
      ) : (
        <QuestionContainer>
          {Questions?.slice(startIndex, endIndex)?.map((question, index) =>
            (() => {
              switch (question.type) {
                case "radio":
                  return (
                    <McqQuestion
                      answer={answer}
                      question={question}
                      key={index}
                      handleOptions={handleOptions}
                    />
                  );
                case "text":
                  return (
                    <TextArea
                      key={index}
                      answer={answer}
                      question={question}
                      handleOptions={handleOptions}
                    />
                  );
                default:
                  return null;
              }
            })()
          )}
        </QuestionContainer>
      )}
      <ButtonContainer>
        <Button
          onClick={() => setActivePage((prev) => Math.max(prev - 1, 1))}
          disabled={activepage === 1}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            activepage === TotalPages
              ? handleSubmit()
              : setActivePage((prev) => prev + 1);
          }}
          disabled={
            activepage === TotalPages &&
            Object.keys(answer).length !== Questions.length
          }
        >
          {activepage === TotalPages ? "Submit" : "Next"}
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
}

export default PreviewTemplate;
