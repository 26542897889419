import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TemplateLogo from "../../assest/Template.png";
import { FiFilter, FiChevronDown } from "react-icons/fi";
import PreviewTemplate from "./Preview/PreviewTemplate";
import { useNavigate } from "react-router-dom";
import fetchExcelToJson from "../../utils/excel";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const TemplateWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;
const Header = styled.header`
  text-align: center;
  margin-bottom: 30px;
`;

const Title = styled.div`
  font-size: 2.5rem;
  margin-bottom: 0;
  color: #111111;
  font-weight: bolder;
`;

const Subtitle = styled.div`
  font-size: 2.5rem;
  color: #ff6347;
  margin-top: 0;
  font-weight: bolder;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  color: #666;
  margin: 0 auto;
`;

const Tag = styled.p`
  color: orangered;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  padding: 0.5rem;
  cursor: pointer;
`;

const SurveyTypes = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 1150px) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    max-height: 80vh;
    overflow-y: auto;
    width: 18rem;
  }
`;

const CheckboxList = styled.div`
  flex: 1;
`;

const CheckboxItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;

  @media (max-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 300px;
  margin: auto;
  width: 100%;
  height: 27rem;
  @media (max-width: 850px) {
    height: 29rem;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 0px 20px;
`;

const CardTitle = styled.h3`
  color: #fff;
  padding: 10px;
  position: absolute;
  top: 7rem;
`;

const CardDescription = styled.p`
  color: #333;
  font-size: 0.9rem;
  line-height: 1.3;
  text-align: justify;
  text-justify: inter-word;
  letter-spacing: 0.2px;
`;

const Button = styled.button`
  background-color: ${(props) => (props.primary ? "#ff6347" : "transparent")};
  color: ${(props) => (props.primary ? "#fff" : "#ff6347")};
  border: ${(props) => (props.primary ? "none" : "1px solid #ff6347")};
  padding: 10px 15px;
  border-radius: 50px;
  cursor: ${(props) => (props.disabled ? "none" : "pointer")};
  font-weight: bold;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 5px;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const FilterText = styled.span`
  margin-left: 8px;
  margin-right: 8px;
  font-size: 14px;
  color: #333;
`;

const Overlay = styled.div`
  display: none;
  @media (max-width: 1150px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
`;

const FilterButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 5px;
`;

const Modal = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.error ? "red" : "gray")};
`;
// Filter component
const Filter = ({ onClick }) => {
  return (
    <FilterContainer onClick={onClick}>
      <FilterText>Filter</FilterText>
      <div>
        <FiFilter size={18} />
        <FiChevronDown size={18} />
      </div>
    </FilterContainer>
  );
};

// SurveyCard component
const SurveyCard = ({
  name,
  description,
  imageUrl,
  isMobile,
  onPreview,
  _id,
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const navigate = useNavigate();
  const IsLogin = localStorage.getItem("token");
  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    <Card>
      <CardImage src={imageUrl || TemplateLogo} alt={name} />
      <CardContent>
        <CardTitle>{name}</CardTitle>
        <Button
          primary
          onClick={() =>
            navigate(
              `${
                IsLogin
                  ? ""
                  : `/auth?surveyType=${encodeURIComponent(
                      name
                    )}&TemplateId=${encodeURIComponent(_id)}`
              }`
            )
          }
        >
          Use
        </Button>
        <Button onClick={onPreview}>Preview</Button>
        {isMobile ? (
          <>
            <Tag onClick={toggleDescription}>
              {showDescription ? "Hide Description" : "Read Description"}
            </Tag>
            {showDescription && (
              <CardDescription>{description}</CardDescription>
            )}
          </>
        ) : (
          <CardDescription>{description}</CardDescription>
        )}
      </CardContent>
    </Card>
  );
};

// Templates component
const Templates = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTab, setIsTab] = useState(window.innerWidth <= 1150);
  const [checkedItems, setCheckedItems] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [questionloading, setQuestionloading] = useState(false);
  const [error, setError] = useState(null);
  const [questioError, setQuestioError] = useState(null);
  const [data, setData] = useState();
  const [surveyData, setSurveyData] = useState(null);
  const [jsonData, setJsonData] = useState([]);

  const ApplyFilter = () => {
    const Allcheck = Object.keys(checkedItems).includes("All");
    if (Allcheck) {
      setSurveyData(data);
      toggleFilter();
      setCheckedItems({});
      return;
    }

    const filterData = data?.filter(
      (item) => checkedItems?.[item?.name]?.checked === true
    );
    setSurveyData(filterData);
    toggleFilter();
  };

  const handleFetchExcel = (Id ,name) => {
    fetchExcelToJson(Id, setJsonData, setQuestionloading, setQuestioError ,name);
  };

  const fetchAlltemplates = async () => {
    setLoading(true);
    try {
      const resposne = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/survey/templates`
      );
      const Templates = await resposne.json();
      const filterData = Templates?.templates?.filter(
        (item) => item.type !=="participant"
      );
      setSurveyData(filterData);
      setData(filterData);
    } catch (error) {
      setError("Something Went Wrong! Please try again");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAlltemplates();
  }, []);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleCheckboxChange = (type, e) => {
    setCheckedItems((prev) => ({
      ...prev,
      [type]: {
        checked: e.target.checked,
        type: type,
      },
    }));
  };

  const handlePreview = (survey) => {
    setSelectedSurvey(survey.name);
    setShowPreview(true);
    handleFetchExcel(survey._id ,survey.name);
  };

  const closePreview = () => {
    setShowPreview(false);
    setSelectedSurvey(null);
    setQuestioError(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTab(window.innerWidth <= 1150);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

   console.log(data)
  return (
    <Container>
      <Header>
        <Title>Launch Professional</Title>
        <Title>Surveys in Under</Title>
        <Subtitle>5 Minutes</Subtitle>
        <Description>
          Whether you need a quick start with our expertly crafted pre-built
          surveys or prefer to tailor your questions to fit specific needs,
          we've got you covered.
        </Description>
      </Header>
      {isTab && <Filter onClick={toggleFilter} />}
      {loading ? (
        <Message>Loading templates....</Message>
      ) : error ? (
        <Message error>{error}</Message>
      ) : surveyData?.length === 0 || !surveyData ? (
        <Message>No templates found</Message>
      ) : (
        <TemplateWrapper>
          {showFilter && isTab && <Overlay onClick={toggleFilter} />}
          {(showFilter || !isTab) && (
            <SurveyTypes>
              <CheckboxList>
                <h3>Pulse Surveys </h3>
                <CheckboxItem>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange("All", e)}
                    checked={checkedItems["All"]?.checked || false}
                  />
                  <label>All</label>
                </CheckboxItem>
                {data?.filter((item)=>item?.type==="survey")?.map((survey, index) => (
                  <CheckboxItem key={index}>
                    <input
                      type="checkbox"
                      id={`survey-${index}`}
                      onChange={(e) => handleCheckboxChange(survey?.name, e)}
                      checked={checkedItems[survey?.name]?.checked || false}
                    />
                    <label htmlFor={`survey-${index}`}>{survey?.name}</label>
                  </CheckboxItem>
                ))}

                <h3>Behavioural Assessments</h3>
                {data?.filter((item)=>item?.type==="personality survey")?.map((survey, index) => (
                  <CheckboxItem key={index}>
                    <input
                      type="checkbox"
                      id={`personality-survey-${index}`}
                      onChange={(e) => handleCheckboxChange(survey?.name, e)}
                      checked={checkedItems[survey?.name]?.checked || false}
                    />
                    <label htmlFor={`personality-survey-${index}`}>{survey?.name==="FPA"?" Five Factor Fusion Personality Assessment":survey?.name}</label>
                  </CheckboxItem>
                ))}
                <FilterButtonContainer>
                  <Button
                    onClick={() => {
                      setCheckedItems({});
                      setSurveyData(data);
                      toggleFilter();
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    disabled={Object.keys(checkedItems)?.length === 0}
                    onClick={
                      Object.keys(checkedItems)?.length === 0
                        ? null
                        : () => ApplyFilter()
                    }
                  >
                    Apply
                  </Button>
                </FilterButtonContainer>
              </CheckboxList>
            </SurveyTypes>
          )}
          <CardGrid>
            {surveyData?.map((survey, index) => (
              <SurveyCard
                key={index}
                {...survey}
                isMobile={isMobile}
                onPreview={() => handlePreview(survey)}
              />
            ))}
          </CardGrid>
        </TemplateWrapper>
      )}

      <Modal onClick={closePreview} show={showPreview}>
        {selectedSurvey && (
          <PreviewTemplate
            handleClose={closePreview}
            survey={selectedSurvey}
            Questions={jsonData}
            loading={questionloading}
            error={questioError}
          />
        )}
      </Modal>
    </Container>
  );
};

export default Templates;
