import React from 'react';
import styled from 'styled-components';
import StepSlider from './StepSlider ';
import { MdCreate, MdSend, MdTrackChanges, MdInsights } from 'react-icons/md';
import { FaRobot, FaSyncAlt } from 'react-icons/fa';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.div`
  color: #F5551B;
  font-size: 14px;  
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 50px;
  text-align: center;
`;


const StepsTimeLine = () => {
   
  const steps = [
        {
          title: 'Survey Creation',
          description: 'Design surveys using our pre-built templates. Customize questions to fit your specific needs.',
          icon: <MdCreate/>
        },
        {
          title: 'Distribution',
          description: 'Distribute your surveys easily via email, mobile, QR Code, or web link. Reach your employees wherever they are.',
          icon: <MdSend/>
        },
        {
          title: 'Track Responses',
          description: 'Collect responses in real-time. Watch the data come in live and stay on top of participation rates.',
          icon: <MdTrackChanges/>
        },
        {
          title: 'AI-Powered Analysis',
          description: 'Our AI slices and dices the data to uncover deeper insights. Understand trends, sentiment, and key themes effortlessly.',
          icon: <FaRobot/>
        },
        {
          title: 'Actionable Insights',
          description: 'Turn insights into action. Receive detailed, actionable recommendations to improve your workplace.',
          icon: <MdInsights/>
        },
        {
          title: 'Continuous Improvement',
          description: 'Implement changes and measure their impact. Use continuous feedback to keep improving. Compare results over years to gauge progress.',
          icon: <FaSyncAlt/>
        }
      ];

  return (
    <Container>
      <Header>HOW IT WORKS</Header>
      <Title>Transform Data into Empowerment</Title>
      <Subtitle>Leverage our advanced survey tool to gain deep insights that empower your workforce and drive unparalleled growth</Subtitle>
      <StepSlider steps={steps}/>
    </Container>
  );
};

export default StepsTimeLine;