import React from 'react';
import styled from 'styled-components';
import { FaEnvelope } from 'react-icons/fa';
import { IoMdHome } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
`;


const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 400px;
`;

const Title = styled.h1`
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
`;

const Message = styled.p`
  color: #666;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 5px;
`;

const Icon = styled(FaEnvelope)`
  color: orangered;
  font-size: 24px;
  margin-bottom: 5px;
`;

const HomeButton = styled.button`
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const HomeIcon = styled(IoMdHome)`
  margin-right: 10px;
`;

const WelcomeCard = () => {
    const navigate= useNavigate();
  return (
    <Container>
      <Card>
        <Title>Welcome to Aries! 🎉</Title>
        <Icon />
        <Message>
          We're so excited to have you with us! You're just a few steps away from getting the most out of your experience. Check your inbox for a welcome email with all the details to help you get started.
        </Message>
        <Message>
          If you have any questions, we're here to help. Let's make this journey amazing together!
        </Message>

        <HomeButton onClick={()=> navigate('/home')}>
        <HomeIcon  />
        Go Home
      </HomeButton>
      </Card>
    
    </Container>
  );
};

export default WelcomeCard;