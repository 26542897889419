import React from 'react';
import styled from 'styled-components';
import barChartImg from '../../assest/Barchart.png';
import pieChartImg from '../../assest/pieChart.png';
import { CiCircleCheck } from "react-icons/ci";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 20px 0px;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width:768px){
    flex-direction: column;
    text-align: center;
  }
`;

const Section2 = styled(Section)`
  @media(max-width:768px){
    flex-direction: column-reverse;
  }
`;

const LeftSection = styled.div`
  flex: 1;
`;

const RightSection = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-size: 27px;
  margin-bottom: 10px;
  color:#111111;
  font-weight: 500;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #464646;
  margin-bottom: 20px;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color:#111111;
`;

const ColorDot = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #F5551B;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Analytics = () => {
  const sections = [
    {
      image: barChartImg,
      title: "Powerful Analytics at Your Fingertips",
      subtitle: "Understand your data with our real-time analytics dashboard. Make informed decisions with confidence.",
      features: [
        "Track participation rates",
        "Analyze response patterns",
        "Identify trends and insights",
        "Get Sentiments Analysis"
      ],
      reverse: false
    },
    {
      image: pieChartImg,
      title: "Insightful Reports, Instantly Generated",
      subtitle: "Transform your survey data into actionable insights with our automated report generation feature.",
      features: [
        "Summary of key findings",
        "Visual charts and graphs",
        "Export options (PDF, CSV)"
      ],
      reverse: true
    }
  ];

  const renderFeatures = (features) => (
    <FeatureList>
      {features.map((feature, index) => (
        <FeatureItem key={index}>
          <ColorDot>
            <CiCircleCheck style={{color:"white"}} />
          </ColorDot>
          {feature}
        </FeatureItem>
      ))}
    </FeatureList>
  );

  return (
    <Container>
      {sections.map((section, index) => {
        const SectionComponent = section.reverse ? Section2 : Section;
        return (
          <SectionComponent key={index}>
            {section.reverse ? (
              <>
                <RightSection>
                  <Title>{section.title}</Title>
                  <Subtitle>{section.subtitle}</Subtitle>
                  {renderFeatures(section.features)}
                </RightSection>
                <LeftSection>
                  <Image src={section.image} alt='loading'/>
                </LeftSection>
              </>
            ) : (
              <>
                <LeftSection>
                  <Image src={section.image} alt='loading'/>
                </LeftSection>
                <RightSection>
                  <Title>{section.title}</Title>
                  <Subtitle>{section.subtitle}</Subtitle>
                  {renderFeatures(section.features)}
                </RightSection>
              </>
            )}
          </SectionComponent>
        );
      })}
    </Container>
  );
};

export default Analytics;