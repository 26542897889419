import React from "react";
import styled from "styled-components";
import connectionLogo from "../../assest/connection.png";
import enagagementLogo from "../../assest/engagement.png";
import aiPowerLogo from "../../assest/aiPower.png";
import strategyLogo from "../../assest/Strategy.png";
import growthLogo from "../../assest/Growth.png";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h2`
  text-align: center;
  color: #111111;
  margin-bottom: 30px;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Icon = styled.img`
  margin-bottom: 15px;
`;

const CardTitle = styled.h3`
  color: #111111;
  margin-bottom: 10px;
`;

const CardDescription = styled.p`
  color: #666;
  font-size: 14px;
  line-height: 1.3;
`;

// Reusable Card Component
const FeatureCard = ({ icon, title, description }) => (
  <Card>
    <Icon src={icon} alt={title} />
    <CardTitle>{title}</CardTitle>
    <CardDescription>{description}</CardDescription>
  </Card>
);

// Main Component
const WhyChooseAries = () => {
  const features = [
    {
      icon: connectionLogo,
      title: "Empathy and Connection",
      description:
        "Understand your employees workplace needs and sentiments before they even voice them.",
    },
    {
      icon: enagagementLogo,
      title: "Proactive Engagement",
      description:
        "Be one step ahead in identifying and addressing your employees needs.",
    },
    {
      icon: aiPowerLogo,
      title: "AI-Powered Insights",
      description:
        "Leverage ARIESs advanced AI-driven analytics to transform raw data into meaningful insights.",
    },
    {
      icon: strategyLogo,
      title: "Actionable Strategies",
      description:
        "Turn insights into impact with detailed reports and actionable recommendations.",
    },
    {
      icon: growthLogo,
      title: "Continuous Improvement",
      description:
        "Implement changes and measure their impact, using continuous feedback.",
    },
  ];

  return (
    <Container>
      <Title>Why Choose ARIES?</Title>
      <CardGrid>
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </CardGrid>
    </Container>
  );
};

export default WhyChooseAries;
