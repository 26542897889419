import React from 'react';
import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';
import { BsArrowUpRightCircle } from "react-icons/bs";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h2`
  text-align: center;
  color: #111111;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #464646;
  margin-bottom: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  background-color: #F5551B;
  border-radius: 8px;
  padding: 40px;
  flex: 1;
`;

const CardTitle = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom:1rem;
  margin-top: 0.3rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  color: #FFFFFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const Icon = styled(FaCheckCircle)`
  color: white;
`;

const BestMadeFor = () => {
  const cardData = [
    {
      title: "HR Consulting Firms",
      items: [
        "Integrated tool to easily manage surveys across all clients",
        "Provide data-driven recommendations and solutions to their clients",
        "Ready to use charts and reports for presentation"
      ]
    },
    {
      title: "Organizations",
      items: [
        "Enhance employee experience, and customer relations",
        "Identify areas for improvement",
        "Ready to use charts and reports for presentations"
      ]
    }
  ];

  return (
    <Container>
      <Title>Best made for</Title>
      <Subtitle>Survey is often either focused on employee feedback...</Subtitle>
      <CardContainer>
        {cardData.map((card, index) => (
          <Card key={index}>     
           <BsArrowUpRightCircle style={{color:"white"}} />
            <CardTitle>
         {card.title}
            </CardTitle>
            <List>
              {card.items.map((item, itemIndex) => (
                <ListItem key={itemIndex}>
                  <Icon /> {item}
                </ListItem>
              ))}
            </List>
          </Card>
        ))}
      </CardContainer>
    </Container>
  );
};

export default BestMadeFor;