import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  width: 90%;
  max-width: 800px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  background-color: #ffffff;
  @media (max-width: 768px) {
    width: 95%;
    padding: 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: #007bff;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.4;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 1rem;
`;

const FeatureItem = styled.li`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  &:before {
    content: '✓';
    color: #4CAF50;
    font-weight: bold;
    margin-right: 0.5rem;
  }
`;

const ListHeader = styled.h3`
font-size: 1.2rem;
color: #333;
margin-bottom: -0.3rem;
@media (max-width: 768px) {
    text-align: center;
  }
`
const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;

  &:hover {
    background-color: #45a049;
  }
`;

function FreePlan() {
    const navigate=useNavigate();
  return (
    <Container>
      <Title>Experience Our Platform for Free!</Title>
      <SubTitle>
        We are excited to offer our cutting-edge Pulse Survey and Behavioral Assessment platform 
        to all users at no cost for one month. Take advantage of this exclusive opportunity to 
        explore and benefit from our advanced features without any financial commitment.
      </SubTitle>
      <ListHeader>Why Join Our Beta Trial?</ListHeader>
      <FeatureList>
        <FeatureItem>Enjoy full access to all features and tools at no charge.
        </FeatureItem>
        <FeatureItem>Help us refine and enhance the platform to better meet your needs.
        </FeatureItem>
        <FeatureItem>Be among the first to experience our innovative solutions and influence their development.
        </FeatureItem>
      </FeatureList>
      <ListHeader>How to Get Started</ListHeader>
      <FeatureList>
        <FeatureItem>Register for an account and start using the platform immediately.
        </FeatureItem>
        <FeatureItem> Take full advantage of our comprehensive survey and assessment tools.
        </FeatureItem>
        <FeatureItem>Let us know what you think and how we can improve.
        </FeatureItem>
      </FeatureList>
      <Button onClick={()=>navigate('/auth')}>Start Free Trial</Button>
    </Container>
  );
}

export default FreePlan;