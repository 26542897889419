import React from 'react'
import AboutBanner from './AboutBanner'
import WhyChooseAries from './WhyAries'

function About() {
  return (
    <>
      <AboutBanner/>
      <WhyChooseAries/>
    </>
  )
}

export default About
