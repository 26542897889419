import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FAQ from "../../common/FAQAccordionCard";
import MobilePricingPlans from "./MobileView";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import FreePlan from "./FreePlan";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  color: #111111;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #464646;
  max-width: 50%;
  margin: auto;
  margin-bottom: 20px;
  font-size: 16px;
  @media (max-width: 786px) {
    max-width: 90%;
  }
`;

const Features = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Feature = styled.span`
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  color: #f5551b;
`;

const PricingTable = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  background-color: #f0f0f0;
`;

const TableBody = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
`;

const HeaderCell = styled.div`
  padding: 15px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.isPro ? "#ff5722" : "transparent")};
`;

const Cell = styled.div`
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
  color: ${(props) => (props.color ? "orangered" : "")};
  font-weight: 500;
  /* text-transform: capitalize; */

`;

const PriceCell = styled(Cell)`
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
  color: ${(props) => (props.isPro ? "#fff" : "#333")};
  border: none;
`;
const Enroll = styled.div`
  color: #111111;
  font-size: 14px;
  font-weight: 400;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid orangered;
  width: 30%;
  padding: .2rem;
  margin: auto;
  font-size: 14px;
  background-color: #f0f0f0;
`;
const ToggleSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;
const Tag = styled.div`
  font-size: 14px;
  background: linear-gradient(to bottom, white, #ddd);
  cursor: pointer;
  color: orangered;
  padding: 1rem;
  margin: auto;
  text-align: center;
  font-weight: 500;
  margin-top: -0.5rem;
  position: relative;
  overflow: hidden;
  @media(max-width:768px){
      display: none;
    }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: inherit;
    filter: blur(20px);
  }
`;
const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    min-height: 150px;
    max-width: 90%;
  }
`;

const MessageText = styled.p`
  font-size: 1.2rem;
  color: ${(props)=>props.error?"red":"#33"};
  text-align: center;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PricingPlans = () => {
  const [showAllfeatures, setShowAllfeatures] = useState(false);
  const [plansData ,setPlansData]=useState([]);
  const [loading ,setLoading]=useState(false);
  const [error ,setError]=useState(null);

  function makeSentence(str) {
    let words = str.split(/(?=[A-Z])/)
                   .map(word => word.toLowerCase());
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(' ') ;
  }

  const subscriptionFAQ = [
    {
      question: "What is a subscription plan?",
      answer:
        "A subscription plan is a service model where you pay a recurring fee (usually monthly or annually) to access a product or service continuously. It's like renting access for a set period rather than making a one-time purchase.",
    },
    {
      question: "Can I cancel my subscription at any time?",
      answer:
        "Yes, most subscription services allow you to cancel at any time. However, depending on the terms, you might continue to have access until the end of your current billing period.",
    },
    {
      question: "What happens if I forget to renew my subscription?",
      answer:
        "Many services automatically renew subscriptions to prevent interruption. If auto-renewal is not enabled, your access may be suspended until you renew. Some services offer a grace period before full cancellation.",
    },
    {
      question: "Are there different types of subscription plans?",
      answer:
        "Yes, common types include tiered plans (basic, premium, etc.), usage-based plans, and freemium models. Some businesses also offer annual plans at a discount compared to monthly rates.",
    },
    {
      question:
        "How secure is my payment information for recurring subscriptions?",
      answer:
        "Reputable subscription services use secure, encrypted payment processing systems. They typically store your payment information securely for recurring charges. Always check the company's security measures and use strong, unique passwords for your accounts.",
    },
  ];
    
  const fetchPlans =async()=>{
    setLoading(true)
    setError(null)
    try {
      const response =await  fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/subscription/plans`)
      const data = await response.json();
      setPlansData(data?.data);
    } catch (error) {
      setError("Something went wrong!")
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    fetchPlans()
  },[])

  if(loading){
    return(
      <MessageContainer>
      <MessageText>Loading Plans...</MessageText>
    </MessageContainer>
    )
  }
   
  if(error){
    return(
      <MessageContainer>
      <MessageText error>{error}</MessageText>
    </MessageContainer>
    )
  }
  if(plansData?.length===0 && plansData){
    return(
      <MessageContainer>
      <MessageText>No plans are available</MessageText>
    </MessageContainer>
    )
  }
   
  return (
    <Container>
      <Title>Pricing & Plans</Title>

      {/* <Subtitle>
        With lots of unique blocks, you can easily build a page without coding.
        Build your next consultancy website within few minutes.
      </Subtitle> */}
      <Features>
        <Feature>Free for life, never expires</Feature>
        <Feature>No credit card required</Feature>
        <Feature>Upgrade anytime</Feature>
        <Feature>billed annually</Feature>
      </Features>
      <FreePlan/>

      <PricingTable>
        <TableHeader>
          <HeaderCell>Compare plans</HeaderCell>
          {plansData.map((plan, index) => (
            <HeaderCell key={index} isPro={plan.isPro}>
              {plan.planName}
              <PriceCell isPro={plan.isPro}>{plan.price}</PriceCell>
              <Enroll>Enroll now</Enroll>

            </HeaderCell>
          ))}
        </TableHeader>
        <TableBody>
          {plansData.length > 0 &&
            (!showAllfeatures
              ? Object.keys(plansData[0].features)?.slice(0, 3)
              : Object.keys(plansData[0].features)
            ).map((feature, index) => (
              <React.Fragment key={index}>
                <Cell>{makeSentence(feature)}</Cell>
                {plansData.map((plan, planIndex) => (
                  <Cell key={planIndex} color={plan.isPro}>
                    {plan.features[feature]==='Yes'?'✓':plan.features[feature]}
                  </Cell>
                ))}
              </React.Fragment>
            ))}
        </TableBody>
      </PricingTable>
     { <Tag onClick={() => setShowAllfeatures(!showAllfeatures)}>
        {showAllfeatures ? "Hide features" : "Show  All features"}{" "}
        {showAllfeatures ? <FaArrowUp /> : <FaArrowDown />}
      </Tag>}
      <MobilePricingPlans plansData={plansData}  />
      {/* <FAQ faqData={subscriptionFAQ} /> */}
    </Container>
  );
};

export default PricingPlans;
