import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const FAQTitle = styled.h1`
  text-align: center;
  color: #111111;
`;

const FAQItem = styled.div`
  margin-bottom: 10px;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 1px 1px 1px whitesmoke;
`;

const QuestionButton = styled.button`
  width: 100%;
  text-align: left;
  background: #f9f9f9;
  border: none;
  padding: 15px;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #f0f0f0;
  }
`;

const Answer = styled.div`
  padding: 14px;
  background: #fff;
  color: #666;
  font-size: 13px;
  line-height: 1.5;
`;

const FAQ = ({faqData}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FAQContainer>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      {faqData?.map((item, index) => (
        <FAQItem key={index}>
          <QuestionButton onClick={() => toggleQuestion(index)}>
            {item.question}
            {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
          </QuestionButton>
          {openIndex === index && <Answer>{item.answer}</Answer>}
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQ;