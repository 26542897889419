import React from 'react';
import styled from 'styled-components';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom'; 

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f8f8;
  color: #333;
`;

const IconWrapper = styled.div`
  font-size: 5em;
  color: #ff5722;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.2em;
  margin-bottom: 40px;
`;

const HomeButton = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #ff5722;
  border: none;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    background-color: #e64a19;
  }
`;

function NotFoundPage() {
  return (
    <Container>
      <IconWrapper>
        <FaExclamationTriangle />
      </IconWrapper>
      <Title>404 - Page Not Found</Title>
      <Description>Sorry, the page you are looking for does not exist.</Description>
      <HomeButton to="/">Go to Home</HomeButton>
    </Container>
  );
}

export default NotFoundPage;
