import React from "react";
import styled from "styled-components";
import { FaYoutube, FaFacebookF, FaTwitter } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Logo from  '../../assest/Logo.png';
const FooterContainer = styled.footer`
  background-color: #111;
  color: white;
  padding: 0.5rem;
`;

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterSection = styled.div`
  margin-bottom: 1.5rem;
  min-width: 150px;

  @media (max-width: 768px) {
    flex-basis: 50%;
    text-align: center;
  }

  @media (max-width: 480px) {
    flex-basis: 100%;
  }
`;

const FooterSection1 = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    margin: auto;
    gap: 10px;
  }
`;

const FooterTitle = styled.img`
width: 30px;
height: 30px;
`;

const FooterLink = styled(NavLink)`
  color: #ccc;
  text-decoration: none;
  display: block;
  margin-bottom: 0.5rem;

  &:hover {
    color: white;
  }
`;

const ContactInfo = styled.p`
  color: #ccc;
  margin-bottom: 0.5rem;
`;

const BottomBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  padding-top: .5rem;
  border-top: 1px solid #333;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Copyright = styled.p`
  color: #ccc;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const SocialIcon = styled.a`
  color: white;
  font-size: 1.2rem;

  &:hover {
    color: #ccc;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterTitle src={Logo} alt="logo">
          </FooterTitle>
          <ContactInfo>{process.env.REACT_APP_CONTACT_MAIL}</ContactInfo>
          {/* <ContactInfo>+1 (201) 895-3801</ContactInfo> */}
        </FooterSection>
        <FooterSection1>
          <FooterLink to="/home">Home</FooterLink>
          <FooterLink to="/about">About us</FooterLink>
          <FooterLink to="/contact">Book a Demo</FooterLink>
        </FooterSection1>
        {/* <FooterSection style={{display:"flex" ,justifyContent:"center" ,alignItems:"center"  ,gap:"20px"}}> 
        <SocialIcons>
          <SocialIcon href="#"><FaYoutube /></SocialIcon>
          <SocialIcon href="#"><FaFacebookF /></SocialIcon>
          <SocialIcon href="#"><FaTwitter /></SocialIcon>
        </SocialIcons>
        </FooterSection> */}
      </FooterContent>
      <BottomBar>
        <Copyright>
          <a
            href="https://www.onefinitylabs.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#ccc" }}
          >
            © 2024 Onefinity Labs
          </a>
        </Copyright>
      </BottomBar>
    </FooterContainer>
  );
};

export default Footer;
