import styled from "styled-components";
import landingImg1 from "../../assest/landing_img1.png";
import landingImg2 from "../../assest/landing_img2.png";
import { useNavigate } from "react-router-dom";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff5f2;
  background: linear-gradient(to bottom, transparent 20%, #fff5f2);

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1;
  margin-top: 10%;
  padding: 2rem;

  @media (max-width: 767px) {
    margin-top: 12%;
  }
`;

const Right = styled.div`
  padding: 0.5rem;
  flex: 1;
  background: linear-gradient(to left, orangered 50%, transparent 20%);

  @media (max-width: 767px) {
    padding: 0.25rem 0.5rem;
  }
`;

const Heading = styled.h1`
  font-size: 48px;
  color: #111111;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-size: 32px;
    text-align: center;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #464646;
  margin-bottom: 30px;
  line-height: 1.4;

  @media (max-width: 767px) {
    font-size: 15px;
    text-align: center;
  }
`;

const Button = styled.button`
  background-color: #ff5722;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
  width: auto;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  gap: 20px;

  @media (max-width: 767px) {
    gap: 10px;
  }
`;

const Image1 = styled.img`
  width: 85%;
  border-radius: 8px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Image2 = styled.img`
  width: 60%;
  border-radius: 8px;
  float: right;
  margin-right: 15%;

  @media (max-width: 767px) {
    width: 80%;
    margin-right: 10%;
  }
`;

// const TopBar = styled.div`
//   color: #f5551b;
//   padding: 10px;
//   text-align: center;
//   border-radius: 50px;
//   border: 0.5px solid #f5551b;
//   background: rgba(245, 85, 27, 0.05);
//   font-size: 14px;
//   width: 55%;
//   text-align: center;
//   @media (max-width: 768px) {
//     width: 90%;
//   }
// `;

function LandingPage() {
  const navigate = useNavigate();
  const IsLogin = localStorage.getItem("token");
  return (
    <>
      <Page>
        <Left>
          <Heading>Discover the Pulse of Your Organization
          with ARIES</Heading>
          <Paragraph>
           Understand your team’s needs and emotions before they voice them. Create an environment where everyone feels heard and valued. Empower your organization with insights for smart hiring.
          </Paragraph>
          <Button onClick={() => navigate(`${IsLogin ? "" : "/auth"}`)}>
            Get Started Today
          </Button>
        </Left>
        <Right>
          <ImageContainer>
            <Image1 src={landingImg1} alt="Team meeting" />
            <Image2 src={landingImg2} alt="Office collaboration" />
          </ImageContainer>
        </Right>
      </Page>
    </>
  );
}

export default LandingPage;
