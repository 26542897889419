import React from "react";
import styled from "styled-components";
import AboutBannerImg1 from "../../assest/aboutBanner1.png";
import AboutBannerImg2 from "../../assest/aboutBanner2.png";
import { NavLink } from "react-router-dom";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.h1`
  color: #111111;
  text-align: center;
  font-size: 30px;

  @media (max-width: 950px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  margin-bottom: 0;
  margin-right: 20px;
  position: relative;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
const Image1= styled(Image)`
@media (max-width: 768px) {
    margin-left:38%;
    margin-top:-20%;
    width:70%;
  }
`


const TextSection = styled.div`
  flex: 1;
  padding: 2rem;
  @media(max-width:950px){
    padding: 0.5rem;
  }
  @media(max-width:768px){
    text-align: center;
  }
`;

const Paragraph = styled.p`
  color: #666;
  line-height: 1.5;
  text-align: justify;
  margin: 0.5rem;
  font-size: 15px;
  font-weight: 400;
  @media(max-width:768px){
    text-align: center;
    margin-bottom:0.8rem;
    font-size: 16px;
  }
`;

const AboutBanner = () => {
  return (
    <Container>
      <ContentWrapper>
        <ImageSection>
          <Image src={AboutBannerImg1} alt="ARIES team" />
          {/* <Image1 src={AboutBannerImg2} alt="ARIES in use" /> */}
        </ImageSection>
        <TextSection>
          <Header>
            Empowering Workplaces with ARIES: The Ultimate Pulse Survey Tools and Behavioral Assessments Platform
          </Header>
          <Paragraph>
            ARIES empowers HR professionals and consulting partners to gain deep
            insights into their workforce, enabling them to anticipate needs and
            foster a more engaged, productive, and satisfied workplace.
          </Paragraph>
          <Paragraph>
            ARIES has been built in partnership with <NavLink to='https://tabularasaconsulting.in' target="_blank">Tabula Rasa Consulting</NavLink>, an
            Organization Development Consultant, ensuring that our tool is
            grounded in expert knowledge and industry best practices.
          </Paragraph>
          <Paragraph>
            With ARIES, you can capture the silent signals of your employees'
            well-being and turn valuable data into actionable strategies,
            ensuring your organization thrives.
          </Paragraph>
        </TextSection>
      </ContentWrapper>
    </Container>
  );
};

export default AboutBanner;
