/* eslint-disable */
import styled from "styled-components";
import React, { useState } from "react";

const RatingContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
  padding: 1rem;
`;

const Label = styled.h6`
  color: #111111;
  font-weight: bold;
`;

const RatingOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const RatingOption = styled.button`
  width: 30px;
  height: 30px;
  background-color: ${(props) => (props.selected ? "#FFEAEC" : "#F4F4F4")};
  color: ${(props) => (props.selected ? "#F5551B" : "black")};
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  border-radius: 4px;
  border: none;
`;
const Range = styled.div`
  display: flex;
`;
const Rating = styled.div`
  display: flex;
  gap: 5px;
`;
const RatingFactor = styled.h6`
  display: flex;
  color: #756e6e;
  width: 10rem;
  margin: 0.3rem;
  font-weight: 600;
  margin-right: 1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  @media (max-width: 767px) {
    width: 8rem;
  }
`;
function RatingComponent({ item, dataMap, HandleAnswers }) {
  const isSelected = (questionNumber, fieldIndex, optionIndex) => {
    const questionData = dataMap.get(questionNumber) || [];
    return questionData.some(
      (item) =>
        item.fieldIndex === fieldIndex && item.optionIndex === optionIndex
    );
  };

  return (
    <RatingContainer>
      <Label>
        {item.rowNumber}.{item.statement}
      </Label>
      <RatingOptions>
        {item?.fields.map((option, fieldIndex) => (
          <Rating key={fieldIndex}>
            <RatingFactor>
              {String.fromCharCode(65 + fieldIndex)}.{option?.field}
            </RatingFactor>
            <Range>
              {Array.from({ length: 5 }, (_, OptionIndex) => (
                <RatingOption
                  key={OptionIndex + 1}
                  selected={isSelected(
                    item.rowNumber - 1,
                    fieldIndex,
                    OptionIndex
                  )}
                  onClick={() =>
                    HandleAnswers(item.rowNumber - 1, fieldIndex, OptionIndex)
                  }
                >
                  {OptionIndex + 1}
                </RatingOption>
              ))}
            </Range>
          </Rating>
        ))}
      </RatingOptions>
    </RatingContainer>
  );
}

export default RatingComponent;
