import React, { useState } from "react";
import styled from "styled-components";

const QuestionWrapper = styled.div`
  margin-bottom: 10px;
`;

const QuestionText = styled.p`
  font-weight: 900;
  font-size: 14px;
  margin-bottom: 7px;
  margin-top: 2px;
  line-height: 20px;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
`;

const OptionButton = styled.button`
  border: 1px solid #ccc;
  background-color: ${(props) => (props.selected ? "#007bff" : "white")};
  color: ${(props) => (props.selected ? "white" : "black")};
  cursor: pointer;
  margin: 2px 0;
  color: #000000;
  font-size: 12px;
  padding: 0px 8px;
  margin: 2px;
  border-radius: 4px;
  height: 24px;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const McqQuestion = ({ question, handleOptions, answer }) => {
  return (
    <QuestionWrapper>
      <QuestionText>
        {question.questionNumber}.{question.questionStatement}
      </QuestionText>
      <OptionsContainer>
        {question?.options?.map((option, index) => (
          <OptionButton
            key={index}
            onClick={() => handleOptions(question.questionNumber - 1, index)}
            selected={answer[question.questionNumber - 1] === index || false}
          >
            {option.label}
          </OptionButton>
        ))}
      </OptionsContainer>
    </QuestionWrapper>
  );
};

export default McqQuestion;
