import React from "react";
import LandingPage from "./LandingPage";
import StepsTimeLine from "./TimeLine";
import FeatureSection from "./Features";
import Analytics from "./Analytics";
import BestMadeFor from "./BestMadeFor";
import Contact from "../Contact/Contact";
import FAQ from "../../common/FAQAccordionCard";

function Home() {
    const faqData = [
        {
          question: "Does ARIES have survey templates?",
          answer: "Yes, ARIES offers survey templates to help you get started quickly."
        },
        {
          question: "Can I customize a survey on ARIES?",
          answer: "Yes, you can fully customize surveys on ARIES to meet your specific needs."
        },
        {
          question: "Can I have multiple surveys running on ARIES?",
          answer: "Yes, ARIES supports running multiple surveys simultaneously."
        },
        {
          question: "Can ARIES send out auto reminders to participants / target groups?",
          answer: "Yes, ARIES can automatically send reminders to participants and target groups."
        },
        {
          question: "Can I track the number of responses in real-time?",
          answer: "Yes, ARIES provides real-time tracking of response numbers."
        },
        {
          question: "Can ARIES generate reports based on the responses?",
          answer: "Yes, ARIES can generate comprehensive reports based on survey responses."
        },
        {
          question: "Is the data on ARIES safe & private?",
          answer: "Yes, ARIES ensures the safety and privacy of all data stored on the platform."
        },
        {
          question: "Can ARIES provide me with comparative analysis across teams or groups or year-on-year progress?",
          answer: "Yes, ARIES offers comparative analysis features for teams, groups, and year-on-year progress."
        }
      ];
  return (
    <>
      <LandingPage />
      <StepsTimeLine />
      <FeatureSection />
      <Analytics />
      <BestMadeFor />.
      <Contact />
      <FAQ faqData={faqData} />
    </>
  );
}

export default Home;
