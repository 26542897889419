import React, { useState } from "react";
import styled from "styled-components";
import { FaCalendarAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { PopupButton } from "react-calendly";
import ContactForm from "./contactForm";

const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: #f5551b;
  color: white;
  padding: 0px 40px;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 20px;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  text-align: center;
  color: #111111;
  height: 12rem;
  padding: 50px;
  @media (max-width: 768px) {
    border-radius: 8px;
    box-shadow: 2px 2px 2px white;
    padding: 20px;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  background-color: white;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #464646;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  svg {
    margin-right: 10px;
  }
`;

const ContactInfo = styled.div`
  margin-top: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InfoItem = styled.p`
  margin-top: -5px;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
const InfoHeader = styled(InfoItem)`
  font-weight: bold;
`;

const InfoContainer = styled.div`
  flex: 1;
`;

const StyledPopupButton = styled(PopupButton)`
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  svg {
    margin-right: 10px;
  }
`;
function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showForm, setShowForm] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", { name, email, message });
  };
  return (
    <ContactContainer id="contact">
      <LeftSection>
        <Title>Get In Touch</Title>
        <ButtonGroup>
          <StyledPopupButton
            styles={{
              background: "white",
              color: "#464646",
              fontWeight: "bold",
            }}
            url={process.env.REACT_APP_CALENDLY_URL}
            rootElement={document.getElementById("root")}
            text={
              <>
                <FaCalendarAlt /> Book a Demo
              </>
            }
          ></StyledPopupButton>
          <Button onClick={() => setShowForm(!showForm)}>
            <FaPhoneAlt /> Contact Us
          </Button>
          <Button  onClick={() => setShowForm(!showForm)}>
            <FaEnvelope />
              Email Us
          </Button>
        </ButtonGroup>
      </LeftSection>
      <RightSection>
        {!showForm ? (
          <>
            <Title>We've been waiting for you</Title>
            <ContactInfo>
              <InfoContainer>
                <InfoHeader>
                  <FaEnvelope style={{ color: "orangered" }} /> Email us
                </InfoHeader>
                <InfoItem>{process.env.REACT_APP_CONTACT_MAIL}</InfoItem>
                {/* <InfoItem>contact@example.io</InfoItem> */}
              </InfoContainer>
            </ContactInfo>
          </>
        ) : (
          <ContactForm
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            message={message}
            setMessage={setMessage}
            handleSubmit={handleSubmit}
          />
        )}
      </RightSection>
    </ContactContainer>
  );
}

export default Contact;
