import React from 'react';
import styled from 'styled-components';
import BannerImg  from '../assest/Banner.jpeg'
import { useNavigate } from "react-router-dom";

const BannerContainer = styled.div`
  background-color: #F5551B; // Orange background color
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-radius: 10px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextContent = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

const Button = styled.button`
  background-color: white;
  color: #FF5722;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #FF7F50;
    color: white;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Image = styled.img`
  height:20rem;
  border-radius:14px;
  transform: rotate(30deg ) ;
  width: 14rem;

`;

const TransformBanner = () => {
  const navigate =useNavigate();
  const IsLogin =localStorage.getItem('token');
  return (
    <BannerContainer>
      <TextContent>
        <Title>Ready to Transform Your Workforce?</Title>
        <Subtitle>Empower your team with employee engagement and satisfaction tools</Subtitle>
        <Button onClick={()=>navigate(`${IsLogin ?"":'/auth'}`)}> Get started now</Button>
      </TextContent>
      <ImageContainer>
        <Image src={BannerImg} alt="Workforce transformation illustration" />
      </ImageContainer>
    </BannerContainer>
  );
};

export default TransformBanner;