import React, { useState } from "react";
import styled from "styled-components";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;

const StyledInput = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

const StyledTextarea = styled.textarea`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
`;

const SubmitButton = styled.button`
  background-color: #ff4500;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ff6347;
  }
`;

function ContactForm({handleSubmit ,setName ,setEmail ,setMessage ,name  , email ,message}) {

  return (
    <FormContainer onSubmit={handleSubmit}>
      <StyledInput
        type="text"
        name="name"
        placeholder="Your Name"
        value={name}
        onChange={(e)=>setName(e.target.value)}
        required
      />
      <StyledInput
        type="email"
        name="email"
        placeholder="Your Email"
        value={email}
        onChange={(e)=>setEmail(e.target.value)}
        required
      />
      <StyledTextarea
        name="message"
        placeholder="Your Message"
        value={message}
        onChange={(e)=>setMessage(e.target.value)}
        required
      />
      <SubmitButton type="submit">Send Message</SubmitButton>
    </FormContainer>
  );
}

export default ContactForm;